
body {
  overflow-y: scroll;  /*to always show the scrollbar*/
}

.background_image {
  background-image: url("./images/Background.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 80vh; 
}


